<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Name<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.name"
                  :rules="[v => !!v || 'Name is required']"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Code </v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.code"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Chart Of Account<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="accountName"
                  item-value="accountId"
                  v-model="form.chartOfAccountId"
                  :items="listBanks"
                  :filter="filterObject"
                  :rules="[v => !!v || 'Chart Of Account is required']"
                >
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on">
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.accountName"></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="data.item.accountNumber"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn color="success" @click="addItem"><v-icon>mdi-plus</v-icon></v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="form.accountNumbers"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:item.no="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.number="{ item }">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="item.number"
                ></v-text-field>
              </template>
              <template v-slot:item.action="{ index }">
                <v-btn color="error" @click="deleteItem(index)"><v-icon>mdi-delete</v-icon></v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="submit">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "create-bank",
  data: () => ({
    defaultForm: {
      name: "",
      code: "",
      chartOfAccountId: null,
      accountNumbers: [],
    },
    form: {},
    valid: true,
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Number", value: "number", sortable: false },
      { text: "Action", value: "action", sortable: false },
    ],
    listBanks: [],
  }),

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("bank/create", this.form)
          .then(() => {
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    addItem() {
      this.form.accountNumbers.push({
        bankId: null,
        number: "",
      });
    },
    deleteItem(index) {
      this.form.accountNumbers.splice(index, 1);
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return (
        item.accountName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.accountNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    async getBanks() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("chartOfAccount/getBanks")
        .then(response => {
          this.listBanks = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.form = Object.assign({}, this.defaultForm);
    this.getBanks();
  },
};
</script>

<style></style>
